<template>
  <v-container fluid>
    <FilterDrawer
      current-component="print-filter"
      @filter="filterPrint"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.name }}</td>
        <td>{{ item.description }}</td>
        <td>
          <ul
            v-for="(print, idx) in item.vendor"
            :key="idx"
            class="pa-0"
          >
            <li>{{ print.trading_name }}</li>
          </ul>
        </td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
        <td class="text-right">
          {{ item.updated_at }}
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission.js'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Gravações',
      new: 'print-new',
      edit: 'print-edit',
      context: 'print-context',
      massAction: 'print-mass-action',
      totalRegister: 1,
      totalPages: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Fornecedor', value: 'print_vendors' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Atualizado em', value: 'updated_at', align: 'end' },
      ],
    },
  }),
  created: function () {
    this.getPrint()

    contextMenu.$once('action', (type) => {
      if (type === 'prints') {
        this.getPrint()
      }
    })
    massAction.$once('action', () => {
      this.getPrint()
    })
  },
  methods: {
    getPrint(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('product_prints', {
          params: {
            page,
            search: this.currentSearch,
          },
        })
        .then((res) => {
          this.dataTable.totalPages = res.data.total_pages
          this.dataTable.items = res.data.data
          this.dataTable.totalRegister = res.data.total_prints
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    setCurrentPage(page) {
      this.getPrint(page)
    },
    search(search) {
      this.currentSearch = search
      this.getPrint()
    },
    filterPrint(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('product_prints/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getPrint()
    },
  },
}
</script>
